<template>
  <div class="c-getstarted c-getstarted_white">
    <div class="c-getstarted__body-wrapper c-getstarted__body-wrapper_content">
      <div class="c-getstarted__body c-getstarted__body_content">
        <div class="c-getstarted__body-title c-getstarted__body-title_content">
          <p class="c-getstarted__body-title-text">Find out more <span class="c-getstarted__body-title-text_black">with Sparrow</span></p>
        </div>
        <div class="c-getstarted__body-form c-getstarted__body-form_content">
          <input class="c-getstarted__body-form-input" v-model="name" placeholder="Full Name"/>
          <input class="c-getstarted__body-form-input" v-model="company" placeholder="Company"/>
          <input class="c-getstarted__body-form-input" v-model="contactNumber" placeholder="Contact Number" @keypress="isNumber($event)"/>
          <input class="c-getstarted__body-form-input" v-model="email" placeholder="Email" @input="debouncedEmailCheck"/>
          <div class="c-getstarted__body-form-checkbox-wrapper">
            <div class="c-getstarted__body-form-checkbox" :class="{'c-getstarted__body-form-checkbox_blue': termChecked}" @click="clickTerm()">
              <div class="c-getstarted__body-form-checkbox-checkmark" v-if="termChecked"></div>
            </div>
            <label class="c-getstarted__body-form-checkbox-text">I have read and agreed to terms in the <span class="c-getstarted__body-form-checkbox-text_underline" @click="openLink('/legal/privacy-policies')">Privacy Policy</span></label>
          </div>
          <div class="c-getstarted__body-form-checkbox-wrapper">
            <div class="c-getstarted__body-form-checkbox" :class="{'c-getstarted__body-form-checkbox_blue': informationChecked}" @click="clickInformation()">
              <div class="c-getstarted__body-form-checkbox-checkmark" v-if="informationChecked"></div>
            </div>
            <label class="c-getstarted__body-form-checkbox-text">Please send me information and news updates from Sparrow.</label>
          </div>
          <div class="c-getstarted__body-form-button">
            <button class="c-getstarted__body-form-button-submit" :class="{'c-getstarted__body-form-button-submit_disable': !termChecked || !checkFormValue}" @click="submitData">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _debounce from 'lodash.debounce';
  export default {
    data() {
      return {
        name: '',
        company: '',
        contactNumber: '',
        email: '',
        termChecked: false,
        informationChecked: false,
        validEmail: true,
        preventSpam: false
      }
    },
    mounted() {

    },
    methods: {
      clickTerm() {
        this.termChecked = !this.termChecked
      },
      clickInformation() {
        this.informationChecked = !this.informationChecked
      },
      resetForm() {
        this.name = ""
        this.email = ""
        this.contactNumber = ""
        this.company = ""
        this.termChecked = false
        this.informationChecked = false
      },
      submitData() {
        if(this.termChecked && this.checkFormValue && this.preventSpam == false && this.validEmail) {
          this.preventSpam = true
          let headersApp = {
            'Content-Type': 'application/json'
          };

          let dataContact = {
            "name" : this.name,
            "email" : this.email,
            "phone" : this.contactNumber,
            "companyName" : this.company,
            "subject" : "Get started with Sparrow"
          }

          this.$http.post(this.createApiUrlChestNut('system/contact-us'), dataContact, {headers: headersApp}).then(() => {
            this.preventSpam = false
            this.$eventHub.$emit('show-alert', true, {title: 'Thank you for your query and interest in Sparrow`s digital asset wealth management solutions.', body: 'Our team will be in touch with you shortly.'}, true)
            this.resetForm()
          }, () => {
            this.preventSpam = false
            this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Please try again.'}, false)
          })
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      checkEmailValidity: function(){
        var emailReg = /^([\w-.+0-9]+@([\w-]+.)+[\w-]{2,4})?$/;
        this.validEmail = emailReg.test(this.email);
      }
    },
    computed: {
      checkFormValue() {
        if(this.name != '' && this.company != '' && this.contactNumber != '' && this.email!= '' && this.validEmail) {
          return true
        } else {
          return false
        }
      },
      debouncedEmailCheck: function(){
        return _debounce(this.checkEmailValidity, 500);
      }
    }
  }
</script>
